import React from 'react';

import {Link} from 'react-router-dom';
import Reviews from '../reviews/reviews.component';
import FooterLogo from '../../assets/images/grey-logo.png';

import './footer.styles.scss';

const Footer = () => (
    <div className="footer">
        <div className="row footer-header">
            <h3 className="bungee center cw-dark-blue">Ecstatic Students. Happy Parents.</h3>
        </div>
        
        <div className="bkg-footer">
            <div className="container">
                <Reviews />
                <div className="footer-logo"> 
                    <img src={FooterLogo} alt="Logo" />
                    <h6>COPYRIGHT &copy; CODE WIZ 2025-26</h6>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;