import React from 'react';
import MenuItem from '../menu-item/menu-item.component';

import './menu.styles.scss';

class Menu extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.name,
            menu: [],
            sections: [
                {
                    title: 'Home',
                    linkUrl: '/',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [{
                        title: 'ABOUT US',
                        dpLinkUrl: '/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/contact-us',
                        id: 12
                    }]
                },
                    {
                    title: 'PROGRAMS',
                    linkUrl: '/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [{
                        title: 'ONLINE CLASSES',
                        dpLinkUrl: '/online-coding-classes-and-camps',
                        id: 13
                    }
                    ,
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/findmycourse',
                        id: 14
                    }
                    ]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '/coding-robotics-competitions-for-kids',
                    id: 6,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'FAQs',
                    linkUrl: '/kids-coding-frequently-asked-questions',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }
            ],
            westford: [
                {
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewiz/booking',
                    id: 56,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/westford-ma',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/westford-ma/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/westford-ma/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/westford-ma/contact-us',
                    id: 12
                }]
                },
                {
                title: 'PROGRAMS',
                linkUrl: '/westford-ma/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/westford-ma/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/westford-ma/girl-scout-badges',
                //     id: 14
                // }
                // ,
                // {
                //     title: 'BIRTHDAY PARTIES',
                //     dpLinkUrl: '/westford-ma/birthday-parties',
                //     id: 15
                // }
            ]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/westford-ma/in-person-classes',
                    id: 60
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/westford-ma/online-classes',
                    id: 16
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/westford-ma/parks-and-rec-partnerships',
                //     id: 17
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/westford-ma/findmycourse',
                    id: 18
                }
                ]
            },
            {
                title: 'SUMMER PROGRAMS',
                linkUrl: '/westford-ma/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/westford-ma/first-lego-league',
                    id: 19
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/westford-ma/virtual-robotics-competition',
                //     id: 20
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/westford-ma/technovation-challenge',
                    id: 21
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/westford-ma/acsl-competition',
                    id: 22
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/westford-ma/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/westford-ma/code-wiz-locations',
                id: 8,
                dropdown: []
            },
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],
            westborough:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewizwestborough/booking',
                id: 57,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/westborough-ma',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/westborough-ma/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/westborough-ma/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/westborough-ma/contact-us',
                    id: 12
                }]
                },
                {
                title: 'PROGRAMS',
                linkUrl: '/westborough-ma/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/westborough-ma/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/westborough-ma/girl-scout-badges',
                //     id: 14
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/westborough-ma/birthday-parties',
                    id: 15
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/westborough-ma/in-person-classes',
                    id: 61
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/westborough-ma/online-classes',
                    id: 23
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/westborough-ma/parks-and-rec-partnerships',
                //     id: 24
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/westborough-ma/findmycourse',
                    id: 25
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/westborough-ma/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/westborough-ma/first-lego-league',
                    id: 26
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/westborough-ma/virtual-robotics-competition',
                //     id: 27
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/westborough-ma/technovation-challenge',
                    id: 28
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/westborough-ma/acsl-competition',
                    id: 29
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/westborough-ma/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/westborough-ma/code-wiz-locations',
                id: 8,
                dropdown: []
            },
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],

            reading:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewizreading/booking',
                id: 60,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/reading-ma',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/reading-ma/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/reading-ma/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/reading-ma/contact-us',
                    id: 12
                }]
                },
                {
                title: 'PROGRAMS',
                linkUrl: '/reading-ma/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/reading-ma/after-school-coding-programs',
                    id: 30
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/reading-ma/girl-scout-badges',
                //     id: 31
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/reading-ma/birthday-parties',
                    id: 32
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '/reading-ma/classes',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/reading-ma/in-person-classes',
                    id: 62
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/reading-ma/online-classes',
                    id: 33
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/reading-ma/parks-and-rec-partnerships',
                //     id: 34
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/reading-ma/findmycourse',
                    id: 35
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/reading-ma/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/reading-ma/first-lego-league',
                    id: 36
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/reading-ma/virtual-robotics-competition',
                //     id: 37
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/reading-ma/technovation-challenge',
                    id: 38
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/reading-ma/acsl-competition',
                    id: 39
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/reading-ma/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/reading-ma/code-wiz-locations',
                id: 8,
                dropdown: []
            },
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],
            jersey:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewiz/booking',
                id: 59,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/jerseycity-nj',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/jerseycity-nj/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/jerseycity-nj/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/jerseycity-nj/contact-us',
                    id: 12
                }]
                },
                {
                title: 'PROGRAMS',
                linkUrl: '/jerseycity-nj/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/jerseycity-nj/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/jerseycity-nj/girl-scout-badges',
                //     id: 14
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/jerseycity-nj/birthday-parties',
                    id: 15
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/jerseycity-nj/in-person-classes',
                    id: 63
                },
                //{
                //     title: 'ONLINE CLASSES',
                //     dpLinkUrl: '/jerseycity-nj/online-classes',
                //     id: 40
                // },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/jerseycity-nj/parks-and-rec-partnerships',
                //     id: 41
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/jerseycity-nj/findmycourse',
                    id: 42
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/jerseycity-nj/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/jerseycity-nj/first-lego-league',
                    id: 43
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/jerseycity-nj/virtual-robotics-competition',
                //     id: 44
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/jerseycity-nj/technovation-challenge',
                    id: 45
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/jerseycity-nj/acsl-competition',
                    id: 46
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/jerseycity-nj/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/jerseycity-nj/code-wiz-locations',
                id: 8,
                dropdown: []
            },
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],

            arlington:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewizarlington/booking/',
                id: 57,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/arlington-ma',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/arlington-ma/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/arlington-ma/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/arlington-ma/contact-us',
                    id: 12
                }]
            },
            {
                title: 'PROGRAMS',
                linkUrl: '/arlington-ma/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/arlington-ma/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/arlington-ma/girl-scout-badges',
                //     id: 14
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/arlington-ma/birthday-parties',
                    id: 15
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/arlington-ma/in-person-classes',
                    id: 64
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/arlington-ma/online-classes',
                    id: 48
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/arlington-ma/parks-and-rec-partnerships',
                //     id: 49
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/arlington-ma/findmycourse',
                    id: 50
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/arlington-ma/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/arlington-ma/first-lego-league',
                    id: 51
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/arlington-ma/virtual-robotics-competition',
                //     id: 52
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/arlington-ma/technovation-challenge',
                    id: 53
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/arlington-ma/acsl-competition',
                    id: 15
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/arlington-ma/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/arlington-ma/code-wiz-locations',
                id: 8,
                dropdown: []
            },                  
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],

            medford:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewizarlington/booking/',
                id: 58,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/medford-ma',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/medford-ma/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/medford-ma/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/medford-ma/contact-us',
                    id: 12
                }]
            },
            {
                title: 'PROGRAMS',
                linkUrl: '/medford-ma/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/medford-ma/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/medford-ma/girl-scout-badges',
                //     id: 14
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/medford-ma/birthday-parties',
                    id: 15
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/medford-ma/in-person-classes',
                    id: 65
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/medford-ma/online-classes',
                    id: 55
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/medford-ma/parks-and-rec-partnerships',
                //     id: 56
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/medford-ma/findmycourse',
                    id: 57
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/medford-ma/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/medford-ma/first-lego-league',
                    id: 13
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/medford-ma/virtual-robotics-competition',
                //     id: 14
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/medford-ma/technovation-challenge',
                    id: 15
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/medford-ma/acsl-competition',
                    id: 58
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/medford-ma/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/medford-ma/code-wiz-locations',
                id: 8,
                dropdown: []
            },                  
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],
            nashua:[{
                title: 'Parent Portal',
                linkUrl: 'https://app.iclasspro.com/portal/codewiznashua/booking',
                id: 58,
                htmlId: 'parent-portal-mobile',
                fontAwesome: 'fa fa-user-circle',
                dropdown: []
                },
                {
                title: 'Home',
                linkUrl: '/nashua-nh',
                id: 1,
                htmlId: 'homeDropdownMenu',
                dropdown: [
                {
                    title: 'ABOUT US',
                    dpLinkUrl: '/nashua-nh/about-us',
                    id: 10
                },
                // {
                //     title: 'BLOG',
                //     dpLinkUrl: '/nashua-nh/blog',
                //     id: 11
                // },
                {
                    title: 'CONTACT US',
                    dpLinkUrl: '/nashua-nh/contact-us',
                    id: 12
                }]
            },
            {
                title: 'PROGRAMS',
                linkUrl: '/nashua-nh/after-school-coding-programs',
                id: 2,
                htmlId: '',
                dropdown: [{
                    title: 'OVERVIEW',
                    dpLinkUrl: '/nashua-nh/after-school-coding-programs',
                    id: 13
                },
                // {
                //     title: "GIRL SCOUTS",
                //     dpLinkUrl: '/nashua-nh/girl-scout-badges',
                //     id: 14
                // },
                {
                    title: 'BIRTHDAY PARTIES',
                    dpLinkUrl: '/nashua-nh/birthday-parties',
                    id: 15
                }]
            },
            {
                title: 'CLASSES',
                linkUrl: '#',
                id: 4,
                htmlId: '',
                dropdown: [{
                    title: 'IN-PERSON CLASSES',
                    dpLinkUrl: '/nashua-nh/in-person-classes',
                    id: 66
                },{
                    title: 'ONLINE CLASSES',
                    dpLinkUrl: '/nashua-nh/online-classes',
                    id: 55
                },
                // {
                //     title: 'REC CENTERS',
                //     dpLinkUrl: '/nashua-nh/parks-and-rec-partnerships',
                //     id: 56
                // }
                // ,
                {
                    title: 'FIND A COURSE',
                    dpLinkUrl: '/nashua-nh/findmycourse',
                    id: 57
                }
                ]
            },
            {
                title: 'CAMPS',
                linkUrl: '/nashua-nh/camps',
                id: 5,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'COMPETITIONS',
                linkUrl: '#',
                id: 6,
                htmlId: '',
                dropdown: [{
                    title: 'FIRST LEGO LEAGUE',
                    dpLinkUrl: '/nashua-nh/first-lego-league',
                    id: 13
                },
                // {
                //     title: 'VIRTUAL ROBOTICS',
                //     dpLinkUrl: '/nashua-nh/virtual-robotics-competition',
                //     id: 14
                // },
                {
                    title: "GIRL'S TECHNOVATION",
                    dpLinkUrl: '/nashua-nh/technovation-challenge',
                    id: 15
                },
                {
                    title: 'ACSL',
                    dpLinkUrl: '/nashua-nh/acsl-competition',
                    id: 58
                }]
            },
            {
                title: 'FAQs',
                linkUrl: '/nashua-nh/faqs',
                id: 7,
                htmlId: '',
                dropdown: []
            },
            {
                title: 'LOCATIONS',
                linkUrl: '/nashua-nh/code-wiz-locations',
                id: 8,
                dropdown: []
            },                  
            {
                title: 'FRANCHISING',
                linkUrl: 'https://codewizfranchise.com/',
                id: 9,
                dropdown: []
            }],
            needham:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizneedham/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/needham-ma',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/needham-ma/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/needham-ma/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/needham-ma/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/needham-ma/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/needham-ma/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/needham-ma/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/needham-ma/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/needham-ma/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'REC CENTERS',
                        dpLinkUrl: '/needham-ma/parks-and-rec-partnerships',
                        id: 24
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/needham-ma/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/needham-ma/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/needham-ma/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/needham-ma/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/needham-ma/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/needham-ma/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/needham-ma/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/needham-ma/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }],

                lawrenceville:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizlawrenceville/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/lawrenceville-ga',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/lawrenceville-ga/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/lawrenceville-ga/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/lawrenceville-ga/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/lawrenceville-ga/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/lawrenceville-ga/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/lawrenceville-ga/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/lawrenceville-ga/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/lawrenceville-ga/in-person-classes',
                        id: 61
                    }
                    // ,{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/lawrenceville-ga/online-classes',
                    //     id: 23
                    // }
                    ,
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/lawrenceville-ga/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/lawrenceville-ga/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/lawrenceville-ga/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/lawrenceville-ga/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/lawrenceville-ga/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/lawrenceville-ga/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/lawrenceville-ga/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/lawrenceville-ga/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/lawrenceville-ga/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Lawrenceville

                plano:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizplano/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/plano-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/plano-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/plano-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/plano-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/plano-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/plano-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/plano-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/plano-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/plano-tx/in-person-classes',
                        id: 61
                    },
                    //,{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/plano-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/plano-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/plano-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/plano-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/plano-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/plano-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/plano-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/plano-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/plano-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/plano-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Plano

                stjohns:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizstjohns/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/stjohns-fl',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/stjohns-fl/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/stjohns-fl/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/stjohns-fl/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/stjohns-fl/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/stjohns-fl/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/stjohns-fl/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/stjohns-fl/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/stjohns-fl/in-person-classes',
                        id: 61
                    },
                    // {
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/stjohns-fl/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/stjohns-fl/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/stjohns-fl/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/stjohns-fl/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/stjohns-fl/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/stjohns-fl/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/stjohns-fl/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/stjohns-fl/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/stjohns-fl/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/stjohns-fl/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //stjohns

                houston:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizoakforest/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/oakforest-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/oakforest-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/oakforest-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/oakforest-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/oakforest-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/oakforest-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/oakforest-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/oakforest-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/oakforest-tx/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/oakforest-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/oakforest-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/oakforest-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/oakforest-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/oakforest-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/oakforest-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/oakforest-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/oakforest-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/oakforest-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/oakforest-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Houston

                durham:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizdurham/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/durham-nc',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/durham-nc/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/durham-nc/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/durham-nc/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/durham-nc/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/durham-nc/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/durham-nc/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/durham-nc/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/durham-nc/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/durham-nc/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/durham-nc/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/durham-nc/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/durham-nc/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/durham-nc/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/durham-nc/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/durham-nc/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/durham-nc/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/durham-nc/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/durham-nc/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Durham

                cypress:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizcypress/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/cypress-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/cypress-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/cypress-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/cypress-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/cypress-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/cypress-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/cypress-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/cypress-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/cypress-tx/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/cypress-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/cypress-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/cypress-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/cypress-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/cypress-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/cypress-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/cypress-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/cypress-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/cypress-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/cypress-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Cypress

                tierrasanta:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewiztierrasanta/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/tierrasanta-ca',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/tierrasanta-ca/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/tierrasanta-ca/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/tierrasanta-ca/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/tierrasanta-ca/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/tierrasanta-ca/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/tierrasanta-ca/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/tierrasanta-ca/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/tierrasanta-ca/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/tierrasanta-ca/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/tierrasanta-ca/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/tierrasanta-ca/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/tierrasanta-ca/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/tierrasanta-ca/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/tierrasanta-ca/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/tierrasanta-ca/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/tierrasanta-ca/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/tierrasanta-ca/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/tierrasanta-ca/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Tierrasanta

                carmel:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizcarmel/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/carmel-in',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/carmel-in/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/carmel-in/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/carmel-in/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/carmel-in/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/carmel-in/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/carmel-in/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/carmel-in/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    // {
                    //     title: 'IN-PERSON CLASSES',
                    //     dpLinkUrl: '/carmel-in/in-person-classes',
                    //     id: 61
                    // },{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/carmel-in/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/carmel-in/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/carmel-in/findmycourse',
                        id: 25
                    }]
                },
                // {
                //     title: 'SUMMER',
                //     linkUrl: '/carmel-in/camps',
                //     id: 5,
                //     htmlId: '',
                //     dropdown: []
                // },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/carmel-in/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/carmel-in/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/carmel-in/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/carmel-in/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/carmel-in/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/carmel-in/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Carmel

                fishhawk:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizfishhawk/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/fishhawk-fl',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/fishhawk-fl/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/fishhawk-fl/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/fishhawk-fl/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/fishhawk-fl/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/fishhawk-fl/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/fishhawk-fl/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/fishhawk-fl/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/fishhawk-fl/in-person-classes',
                        id: 61
                    }
                    ,
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/fishhawk-fl/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/fishhawk-fl/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/fishhawk-fl/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/fishhawk-fl/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/fishhawk-fl/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/fishhawk-fl/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/fishhawk-fl/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/fishhawk-fl/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/fishhawk-fl/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/fishhawk-fl/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // FishHawk
                katy:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizkaty/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/katy-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/katy-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/katy-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/katy-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/katy-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/katy-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/katy-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/katy-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/katy-tx/in-person-classes',
                        id: 61
                    }
                    ,
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/katy-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/katy-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/katy-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/katy-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/katy-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/katy-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/katy-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/katy-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/katy-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/katy-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Katy

                kellereast:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizkeller/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/keller-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/keller-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/keller-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/keller-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/keller-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/keller-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/keller-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/keller-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/keller-tx/in-person-classes',
                        id: 61
                    }
                    ,
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/keller-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/keller-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/keller-tx/findmycourse',
                        id: 25
                    }]
                },
                // {
                //     title: 'SUMMER',
                //     linkUrl: '/keller-tx/camps',
                //     id: 5,
                //     htmlId: '',
                //     dropdown: []
                // },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/keller-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/keller-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/keller-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/keller-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/keller-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/keller-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // KellerEast

                colleyville:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizcolleyville/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/colleyville-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/colleyville-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/colleyville-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/colleyville-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/colleyville-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/colleyville-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/colleyville-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/colleyville-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/colleyville-tx/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/colleyville-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/colleyville-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/colleyville-tx/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/colleyville-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/colleyville-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/colleyville-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/colleyville-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Colleyville

                rutherford:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizrutherford/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/rutherford-nj',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/rutherford-nj/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/rutherford-nj/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/rutherford-nj/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/rutherford-nj/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/rutherford-nj/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/rutherford-nj/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/rutherford-nj/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/rutherford-nj/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/rutherford-nj/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/rutherford-nj/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/rutherford-nj/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/rutherford-nj/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/rutherford-nj/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/rutherford-nj/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/rutherford-nj/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Rutherford

                quincy:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizquincy/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/quincy-ma',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/quincy-ma/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/quincy-ma/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/quincy-ma/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/quincy-ma/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/quincy-ma/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/quincy-ma/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/quincy-ma/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/quincy-ma/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/quincy-ma/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/quincy-ma/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/quincy-ma/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/quincy-ma/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/quincy-ma/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/quincy-ma/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/quincy-ma/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Quincy

                chandler:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizchandler/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/chandler-az',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/chandler-az/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/chandler-az/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/chandler-az/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/chandler-az/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/chandler-az/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/chandler-az/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/chandler-az/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/chandler-az/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/chandler-az/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/chandler-az/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/chandler-az/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/chandler-az/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/chandler-az/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/chandler-az/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/chandler-az/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Chandler

                quincy:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizquincy/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/quincy-ma',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/quincy-ma/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/quincy-ma/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/quincy-ma/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/quincy-ma/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/quincy-ma/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/quincy-ma/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/quincy-ma/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/quincy-ma/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/quincy-ma/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/quincy-ma/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/quincy-ma/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/quincy-ma/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/quincy-ma/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/quincy-ma/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/quincy-ma/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Quincy

                
                
                plainsboro:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizplainsboro/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/plainsboro-nj',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/plainsboro-nj/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/plainsboro-nj/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/plainsboro-nj/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/plainsboro-nj/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/plainsboro-nj/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/plainsboro-nj/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/plainsboro-nj/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/plainsboro-nj/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/plainsboro-nj/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/plainsboro-nj/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/plainsboro-nj/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/plainsboro-nj/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/plainsboro-nj/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/plainsboro-nj/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/plainsboro-nj/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Plainsboro

                owasso:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizowasso/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/owasso-ok',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/owasso-ok/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/owasso-ok/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/owasso-ok/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/owasso-ok/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/owasso-ok/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/owasso-ok/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/owasso-ok/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/owasso-ok/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/owasso-ok/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/owasso-ok/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/owasso-ok/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/owasso-ok/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/owasso-ok/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/owasso-ok/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/owasso-ok/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Owasso

                mableton:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizmableton/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/mableton-ga',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/mableton-ga/about-us',
                        id: 10
                    },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/mableton-ga/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/mableton-ga/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/mableton-ga/after-school-coding-programs',
                        id: 13
                    },
                    // hidden{
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/mableton-ga/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/mableton-ga/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/mableton-ga/in-person-classes',
                        id: 61
                    },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/mableton-ga/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/mableton-ga/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/mableton-ga/first-lego-league',
                        id: 26
                    },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/mableton-ga/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/mableton-ga/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/mableton-ga/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/mableton-ga/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], //Mableton

                beaumont:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizbeaumont/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/beaumont-tx',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/beaumont-tx/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/beaumont-tx/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/beaumont-tx/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/beaumont-tx/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/beaumont-tx/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/beaumont-tx/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/beaumont-tx/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/beaumont-tx/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/beaumont-tx/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/beaumont-tx/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/beaumont-tx/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/beaumont-tx/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/beaumont-tx/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/beaumont-tx/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/beaumont-tx/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/beaumont-tx/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/beaumont-tx/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/beaumont-tx/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Beaumont

                edison:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizedison/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/edison-nj',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/edison-nj/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/edison-nj/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/edison-nj/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/edison-nj/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/edison-nj/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/edison-nj/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/edison-nj/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/edison-nj/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/edison-nj/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/edison-nj/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/edison-nj/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/edison-nj/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/edison-nj/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/edison-nj/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/edison-nj/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/edison-nj/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/edison-nj/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/edison-nj/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Edison

                kirkland:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewizkirkland/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/kirkland-wa',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/kirkland-wa/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/kirkland-wa/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/kirkland-wa/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/kirkland-wa/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/kirkland-wa/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/kirkland-wa/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/kirkland-wa/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/kirkland-wa/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/kirkland-wa/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/kirkland-wa/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/kirkland-wa/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/kirkland-wa/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/kirkland-wa/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/kirkland-wa/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/kirkland-wa/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/kirkland-wa/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/kirkland-wa/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/kirkland-wa/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Kirkland

                northstpete:[{
                    title: 'Parent Portal',
                    linkUrl: 'https://app.iclasspro.com/portal/codewiznorthstpete/booking',
                    id: 57,
                    htmlId: 'parent-portal-mobile',
                    fontAwesome: 'fa fa-user-circle',
                    dropdown: []
                },
                {
                    title: 'Home',
                    linkUrl: '/northstpete-fl',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [
                    {
                        title: 'ABOUT US',
                        dpLinkUrl: '/northstpete-fl/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/northstpete-fl/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/northstpete-fl/contact-us',
                        id: 12
                    }]
                },
                {
                    title: 'PROGRAMS',
                    linkUrl: '/northstpete-fl/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: [{
                        title: 'OVERVIEW',
                        dpLinkUrl: '/northstpete-fl/after-school-coding-programs',
                        id: 13
                    },
                    // {
                    //     title: "GIRL SCOUTS",
                    //     dpLinkUrl: '/northstpete-fl/girl-scout-badges',
                    //     id: 14
                    // },
                    {
                        title: 'BIRTHDAY PARTIES',
                        dpLinkUrl: '/northstpete-fl/birthday-parties',
                        id: 15
                    }]
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [
                    {
                        title: 'IN-PERSON CLASSES',
                        dpLinkUrl: '/northstpete-fl/in-person-classes',
                        id: 61
                    },
                    //{
                    //     title: 'ONLINE CLASSES',
                    //     dpLinkUrl: '/northstpete-fl/online-classes',
                    //     id: 23
                    // },
                    // {
                    //     title: 'REC CENTERS',
                    //     dpLinkUrl: '/northstpete-fl/parks-and-rec-partnerships',
                    //     id: 24
                    // },
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/northstpete-fl/findmycourse',
                        id: 25
                    }]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/northstpete-fl/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '#',
                    id: 6,
                    htmlId: '',
                    dropdown: [{
                        title: 'FIRST LEGO LEAGUE',
                        dpLinkUrl: '/northstpete-fl/first-lego-league',
                        id: 26
                    },
                    // {
                    //     title: 'VIRTUAL ROBOTICS',
                    //     dpLinkUrl: '/northstpete-fl/virtual-robotics-competition',
                    //     id: 27
                    // },
                    {
                        title: "GIRL'S TECHNOVATION",
                        dpLinkUrl: '/northstpete-fl/technovation-challenge',
                        id: 28
                    },
                    {
                        title: 'ACSL',
                        dpLinkUrl: '/northstpete-fl/acsl-competition',
                        id: 29
                    }]
                },
                {
                    title: 'FAQs',
                    linkUrl: '/northstpete-fl/faqs',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/northstpete-fl/code-wiz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }], // Northstpete
        }
    }

    render() {
        
        if(this.state.location === "" || typeof this.state.location === "undefined"){
            this.state.menu = this.state.sections;
        }
        else if(this.state.location === "westford"){
            this.state.menu = this.state.westford;
            //this.setState({menu: this.state.westford})
        }
        else if(this.state.location === "westborough"){
            this.state.menu = this.state.westborough;
        }
        else if(this.state.location === "arlington"){
            this.state.menu = this.state.arlington;
        }
        else if(this.state.location === "reading"){
            this.state.menu = this.state.reading;
        }
        else if(this.state.location === "jersey"){
            this.state.menu = this.state.jersey;
        }
        else if(this.state.location === "medford"){
            this.state.menu = this.state.medford;
        }
        else if(this.state.location === "nashua"){
            this.state.menu = this.state.nashua;
        }
        else if(this.state.location === "needham"){
            this.state.menu = this.state.needham;
        }
        else if(this.state.location === "lawrenceville"){
            this.state.menu = this.state.lawrenceville;
        }
        else if(this.state.location === "plano"){
            this.state.menu = this.state.plano;
        }
        else if(this.state.location === "houston"){
            this.state.menu = this.state.houston;
        }
        else if(this.state.location === "oakforest"){
            this.state.menu = this.state.oakforest;
        }
        else if(this.state.location === "durham"){
            this.state.menu = this.state.durham;
        }
        else if(this.state.location === "cypress"){
            this.state.menu = this.state.cypress;
        }
        else if(this.state.location === "carmel"){
            this.state.menu = this.state.carmel;
        }
        else if(this.state.location === "fishhawk"){
            this.state.menu = this.state.fishhawk;
        }
        else if(this.state.location === "kellereast"){
            this.state.menu = this.state.kellereast;
        }
        else if(this.state.location === "chandler"){
            this.state.menu = this.state.chandler;
        }
        else if(this.state.location === "quincy"){
            this.state.menu = this.state.quincy;
        }
        else if(this.state.location === "colleyville"){
            this.state.menu = this.state.colleyville;
        }
        else if(this.state.location === "rutherford"){
            this.state.menu = this.state.rutherford;
        }
        else if(this.state.location === "owasso"){
            this.state.menu = this.state.owasso;
        }
        else if(this.state.location === "plainsboro"){
            this.state.menu = this.state.plainsboro;
        }
        else if(this.state.location === "mableton"){
            this.state.menu = this.state.mableton;
        }
        else if(this.state.location === "beaumont"){
            this.state.menu = this.state.beaumont;
        }
        else if(this.state.location === "edison"){
            this.state.menu = this.state.edison;
        }
        else if(this.state.location === "kirkland"){
            this.state.menu = this.state.kirkland;
        }
        else if(this.state.location === "northstpete"){
            this.state.menu = this.state.northstpete;
        }
        return (
            <div className="menu-navigation">
                <ul className="navbar-nav mr-auto col-md-12">
                {
                    this.state.menu.map(({id,...otherSectionProps}) => (
                        <MenuItem key={id} {...otherSectionProps} />
                    ))
                }
                </ul>
            </div>
            
        );
    }
}

export default Menu;

